export const PRODUCT_GET_REQUEST = 'PRODUCT_GET_REQUEST';
export const PRODUCT_GET_SUCCESS = 'PRODUCT_GET_SUCCESS';
export const PRODUCT_GET_FAIL = 'PRODUCT_GET_FAIL';

export const PRODUCT_POST_REQUEST = 'PRODUCT_POST_REQUEST';
export const PRODUCT_POST_SUCCESS = 'PRODUCT_POST_SUCCESS';
export const PRODUCT_POST_FAIL = 'PRODUCT_POST_FAIL';

export const PRODUCTLIST_GET_REQUEST = 'PRODUCTLIST_GET_REQUEST';
export const PRODUCTLIST_GET_SUCCESS = 'PRODUCTLIST_GET_SUCCESS';
export const PRODUCTLIST_GET_FAIL = 'PRODUCTLIST_GET_FAIL';

export const PRODUCT_DECREMENT_REQUEST = 'PRODUCT_DECREMENT_REQUEST';
export const PRODUCT_DECREMENT_SUCCESS = 'PRODUCT_DECREMENT_SUCCESS';
export const PRODUCT_DECREMENT_FAIL = 'PRODUCT_DECREMENT_FAIL';

export const PRODUCT_INCREMENT_REQUEST = 'PRODUCT_INCREMENT_REQUEST';
export const PRODUCT_INCREMENT_SUCCESS = 'PRODUCT_INCREMENT_SUCCESS';
export const PRODUCT_INCREMENT_FAIL = 'PRODUCT_INCREMENT_FAIL';

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL';
