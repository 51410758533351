export const ORDER_GET_REQUEST = 'ORDER_GET_REQUEST';
export const ORDER_GET_SUCCESS = 'ORDER_GET_SUCCESS';
export const ORDER_GET_FAIL = 'ORDER_GET_FAIL';

export const ORDER_POST_REQUEST = 'ORDER_POST_REQUEST';
export const ORDER_POST_SUCCESS = 'ORDER_POST_SUCCESS';
export const ORDER_POST_FAIL = 'ORDER_POST_FAIL';

export const ORDER_DECREMENT_REQUEST = 'ORDER_DECREMENT_REQUEST';
export const ORDER_DECREMENT_SUCCESS = 'ORDER_DECREMENT_SUCCESS';
export const ORDER_DECREMENT_FAIL = 'ORDER_DECREMENT_FAIL';

export const ORDER_INCREMENT_REQUEST = 'ORDER_INCREMENT_REQUEST';
export const ORDER_INCREMENT_SUCCESS = 'ORDER_INCREMENT_SUCCESS';
export const ORDER_INCREMENT_FAIL = 'ORDER_INCREMENT_FAIL';

export const ORDER_DELETE_REQUEST = 'ORDER_DELETE_REQUEST';
export const ORDER_DELETE_SUCCESS = 'ORDER_DELETE_SUCCESS';
export const ORDER_DELETE_FAIL = 'ORDER_DELETE_FAIL';

export const PAYMENT_SUCCESSFUL = 'PAYMENT_SUCCESSFUL';
